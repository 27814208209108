<template>
  <div>
    <div
      v-if="preferencesSt && preferencesSt.showKanbanFirst"
      id="admin-lead-kanban-view"
      class="lead-view"
    >
      <v-row no-gutters>
        <v-col md="12">
          <lead-kanban />
        </v-col>
      </v-row>
    </div>
    <div v-else id="admin-lead-view" class="lead-view">
      <v-row no-gutters>
        <v-col md="12">
          <lead-table />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  data() {
    return {
      showKanban: false
    };
  },
  components: {
    LeadTable: () => import("@/components/lead/LeadTable.vue"),
    LeadKanban: () => import("@/components/lead/LeadKanban.vue")
  },
  computed: {
    ...mapState({
      preferencesSt: state => state.preferences
    })
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
